import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import RetrieveBLInfo from "../components/retrieve-bl-info/RetrieveBLInfo";

function RetrieveBlInfo() {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <RetrieveBLInfo />
    </i18nContext.Provider>
  );
}

export default RetrieveBlInfo;
